<template>
  <div>
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold text-uppercase">PROMOTION</h1>
          </b-col>
        </b-row>
        <b-row class="no-gutters bg-white-border mt-4" v-bind:class="$isLoading ? 'd-none': 'd-block'">
          <b-col class="px-4 px-sm-5 py-4">
            <b-form-radio
              size="lg"
              class="ml-1 mb-3"
              v-model="form.allMember"
              name="choice"
              :value="true"
            >
              <span class="font-weight-bold">All Users</span>
            </b-form-radio>

            <b-row v-show="form.allMember === true">
              <b-col md="6">
                <b-form-input
                  placeholder="Enter here..."
                  type="text"
                  name="all-user-text"
                  class="mb-3"
                  v-model="form.all"
                  :class="['input-box',{ error: $v.form.all.$error }]"
                ></b-form-input>

                <p class="text-danger" v-if="$v.form.all.$error">This field is required</p>
              </b-col>
            </b-row>

            <b-form-radio
              size="lg"
              class="ml-1 mb-3"
              v-model="form.allMember"
              name="choice"
              :value="false"
            >
              <span class="font-weight-bold">By User group</span>
            </b-form-radio>

            <b-row v-show="form.allMember === false">
              <b-col md="6">
                <InputText
                  textFloat="Register Users"
                  placeholder="Register Users"
                  type="text"
                  name="regisuser"
                  isRequired
                  :isValidate="$v.form.register.$error"
                  :v="$v.form.register"
                  v-model="form.register"
                />
              </b-col>
              <b-col md="6">
                <InputText
                  textFloat="Non-Register Users"
                  placeholder="Non-Register Users"
                  type="text"
                  name="nonregisuser"
                  isRequired
                  :isValidate="$v.form.nonRegister.$error"
                  :v="$v.form.nonRegister"
                  v-model="form.nonRegister"
                />
              </b-col>
            </b-row>

            <!-- Button Cancel  Exit -->
            <b-row class="mt-5">
              <b-col md="6"></b-col>
              <b-col md="6" class="text-sm-right">
                <button
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(0)"
                  :disabled="isDisable"
                >Save</button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </form>

    <!-- Modal Result -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="Delete"
      colorBtnConfirm="danger"
      btnCancel="Close"
      ref="isModalAlertConfirm"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "Promotion",
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
  },
  data() {
    return {
      showPreview: "",
      modalMessage: "",
      isDisable: false,
      isBusy: false,
      isAll: false,
      showAllUser: false,
      showUserGroup: false,
      items: [],
      form: {
        allMember: true,
        all: "",
        register: "",
        nonRegister: "",
      },
    };
  },
  validations: {
    form: {
      all: {
        required: requiredIf(function () {
          return this.form.allMember == true;
        }),
      },
      register: {
        required: requiredIf(function () {
          return this.form.allMember == false;
        }),
      },
      nonRegister: {
        required: requiredIf(function () {
          return this.form.allMember == false;
        }),
      },
    },
  },
  mounted: async function () {
    this.$isLoading = true;
    await this.getData();
    this.$isLoading = false;
  },
  methods: {
    getData: async function () {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/redirection/promotion`,
        null,
        this.$headers,
        null
      );
      if (data.result == 1) {
        this.form = data.detail;
      }
    },
    saveForm: async function (flag) {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isDisable = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/redirection/promotion`,
        null,
        this.$headers,
        this.form
      );
      this.modalMessage = resData.message;
      this.isDisable = false;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();

        await this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
.input-box {
  color: #16274a;
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 5px 10px;
}
</style>